@import url('https://fonts.googleapis.com/css2?family=Cinzel&display=swap');

.mainTitle {
    margin-left: 3%;
    display: inline-block;
    text-align: left;
    font-family: 'Cinzel', serif;
    font-size: 2.5vw;
    color: black;
    animation: text-glow 8s ease infinite;
}

@keyframes text-glow{
    0% {
        text-shadow: 3px 3px 3px #440000;
    }
    25% {
        text-shadow: 3px 3px 3px #6c1a01;
    }
    50% {
        text-shadow:  3px 3px 3px #651d00;
    }
    75% {
        text-shadow:  3px 3px 3px #6c1a01;
    }
    100% {
        text-shadow:  3px 3px 3px #440000;
    }
}

.Header {
    margin: auto;
    background-color: rgba(52, 52, 52, 0.78);
    opacity: 0.6;
    width: 100%;
    min-width: 1000px;
    height: auto;
}


