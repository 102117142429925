@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');

.box {
    margin-left: 3%;
    width: 50%;
    margin-top: 10%;
    text-align: center;
    background-color: rgba(78, 78, 78, 0.75);
    padding: 35px 20px;
    opacity: 0.7;
    border-radius: 10px;
    animation: anim-glow 8s ease infinite;
    height: auto;
}

@keyframes anim-glow {
    0% {
        box-shadow: 0 0 6px 6px #440000;
        border-width: 5px;
    }
    25% {
        box-shadow: 0 0 8px 8px #6c1a01;
        border-width: 5px;
    }
    50% {
        box-shadow: 0 0 14px 14px #651d00;
        border-width: 5px;
    }
    75% {
        box-shadow: 0 0 8px 8px #6c1a01;
        border-width: 5px;
    }
    100% {
        box-shadow: 0 0 6px 6px #440000;
        border-width: 5px;
    }
}

.text {
    height: auto;
    font-family: 'Lora', serif;
    color: black;
    text-shadow: 1px 1px darkred;
    font-size: 1.2vw;
}

.title {
    height: auto;
    font-family: 'Lora', serif;
    color: black;
    text-shadow: 1px 1px darkred;
    font-size: 40px;
}