@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');

button {
    color: black;
    text-shadow: 1px 1px darkred;
    background-color: transparent;
    border-width: 0;
    font-size: 1.5vw;
    font-family: 'Lora', serif;
    text-align: center;
}

.inner {
    display: inline-block;
    margin-left: 5%;
}

#outer
{
    width: 100%;
    height: auto;
    text-align: center;
}

button:hover {
    background-color: darkgrey;
    -webkit-border-radius: 100px;
    -webkit-transition: all 1s ease;
}