.eventsContainer {
    margin-left: 3%;
    width: 50%;
    margin-top: 10%;
    text-align: center;
    background-color: rgba(78, 78, 78, 0.75);
    padding: 35px 20px;
    opacity: 0.7;
    font-family: 'Lora', serif;
    color: black;
    text-shadow: 1px 1px darkred;
    font-size: 1.2vw;
    border-radius: 10px;
    animation: anim-glow 8s ease infinite;
}

