@import url(https://fonts.googleapis.com/css2?family=Cinzel&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lora&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lora&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lora&display=swap);
html {
    background-image: url(/static/media/FSLWebsiteBackground.fd17068c.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: auto;
}

.mainTitle {
    margin-left: 3%;
    display: inline-block;
    text-align: left;
    font-family: 'Cinzel', serif;
    font-size: 2.5vw;
    color: black;
    -webkit-animation: text-glow 8s ease infinite;
            animation: text-glow 8s ease infinite;
}

@-webkit-keyframes text-glow{
    0% {
        text-shadow: 3px 3px 3px #440000;
    }
    25% {
        text-shadow: 3px 3px 3px #6c1a01;
    }
    50% {
        text-shadow:  3px 3px 3px #651d00;
    }
    75% {
        text-shadow:  3px 3px 3px #6c1a01;
    }
    100% {
        text-shadow:  3px 3px 3px #440000;
    }
}

@keyframes text-glow{
    0% {
        text-shadow: 3px 3px 3px #440000;
    }
    25% {
        text-shadow: 3px 3px 3px #6c1a01;
    }
    50% {
        text-shadow:  3px 3px 3px #651d00;
    }
    75% {
        text-shadow:  3px 3px 3px #6c1a01;
    }
    100% {
        text-shadow:  3px 3px 3px #440000;
    }
}

.Header {
    margin: auto;
    background-color: rgba(52, 52, 52, 0.78);
    opacity: 0.6;
    width: 100%;
    min-width: 1000px;
    height: auto;
}



button {
    color: black;
    text-shadow: 1px 1px darkred;
    background-color: transparent;
    border-width: 0;
    font-size: 1.5vw;
    font-family: 'Lora', serif;
    text-align: center;
}

.inner {
    display: inline-block;
    margin-left: 5%;
}

#outer
{
    width: 100%;
    height: auto;
    text-align: center;
}

button:hover {
    background-color: darkgrey;
    -webkit-border-radius: 100px;
    -webkit-transition: all 1s ease;
}
.mainContainer {
    margin-left: 3%;
    width: 50%;
    margin-top: 10%;
    text-align: center;
    background-color: rgba(78, 78, 78, 0.75);
    padding: 35px 20px;
    opacity: 0.7;
    font-family: 'Lora', serif;
    color: black;
    text-shadow: 1px 1px darkred;
    font-size: 30px;
    border-radius: 10px;
    -webkit-animation: anim-glow 8s ease infinite;
            animation: anim-glow 8s ease infinite;
}

.box {
    margin-left: 3%;
    width: 50%;
    margin-top: 10%;
    text-align: center;
    background-color: rgba(78, 78, 78, 0.75);
    padding: 35px 20px;
    opacity: 0.7;
    border-radius: 10px;
    -webkit-animation: anim-glow 8s ease infinite;
            animation: anim-glow 8s ease infinite;
    height: auto;
}

@-webkit-keyframes anim-glow {
    0% {
        box-shadow: 0 0 6px 6px #440000;
        border-width: 5px;
    }
    25% {
        box-shadow: 0 0 8px 8px #6c1a01;
        border-width: 5px;
    }
    50% {
        box-shadow: 0 0 14px 14px #651d00;
        border-width: 5px;
    }
    75% {
        box-shadow: 0 0 8px 8px #6c1a01;
        border-width: 5px;
    }
    100% {
        box-shadow: 0 0 6px 6px #440000;
        border-width: 5px;
    }
}

@keyframes anim-glow {
    0% {
        box-shadow: 0 0 6px 6px #440000;
        border-width: 5px;
    }
    25% {
        box-shadow: 0 0 8px 8px #6c1a01;
        border-width: 5px;
    }
    50% {
        box-shadow: 0 0 14px 14px #651d00;
        border-width: 5px;
    }
    75% {
        box-shadow: 0 0 8px 8px #6c1a01;
        border-width: 5px;
    }
    100% {
        box-shadow: 0 0 6px 6px #440000;
        border-width: 5px;
    }
}

.text {
    height: auto;
    font-family: 'Lora', serif;
    color: black;
    text-shadow: 1px 1px darkred;
    font-size: 1.2vw;
}

.title {
    height: auto;
    font-family: 'Lora', serif;
    color: black;
    text-shadow: 1px 1px darkred;
    font-size: 40px;
}
.eventsContainer {
    margin-left: 3%;
    width: 50%;
    margin-top: 10%;
    text-align: center;
    background-color: rgba(78, 78, 78, 0.75);
    padding: 35px 20px;
    opacity: 0.7;
    font-family: 'Lora', serif;
    color: black;
    text-shadow: 1px 1px darkred;
    font-size: 1.2vw;
    border-radius: 10px;
    -webkit-animation: anim-glow 8s ease infinite;
            animation: anim-glow 8s ease infinite;
}


.contactContainer {
    margin-left: 3%;
    width: 50%;
    margin-top: 10%;
    text-align: center;
    background-color: rgba(78, 78, 78, 0.75);
    padding: 35px 20px;
    opacity: 0.7;
    border-radius: 10px;
    font-family: 'Lora', serif;
    color: black;
    text-shadow: 1px 1px darkred;
    font-size: 1.2vw;
    -webkit-animation: anim-glow 8s ease infinite;
            animation: anim-glow 8s ease infinite;
}

